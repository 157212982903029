.footerBar {
    background-color: #1C1A1D;
}
.footerBar.stickyToBottom {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.footerBar_container {
    width: 83.3vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footerBar_left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.footerBar_logo {
    width: 9.44vw;
    height: 2.43vw;
    margin: 1.04vw 5.55vw 1.04vw 0;
}

.footerBar_devDoc {
    font-weight: 400;
    font-size: 1.11vw;
    line-height: 1.458vw;
    color: rgba(255, 255, 255, .8);
    text-decoration: none;
    cursor: pointer;
}
.footerBar_devDoc:hover {
    color: #FFFFFF;
}

.footerBar_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footerBar_icon {
    margin-left: 30px;
    position: relative;
    opacity: 0.8;
    cursor: pointer;
}
.footerBar_icon:hover {
    opacity: 1;
}
.footerBar_icon:last-of-type {
    margin-left: 36px;
}

.telegramChinaList {
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%) translateY(-5px);
    width: 180px;
    height: 88px;
    background: #FAFAFC;
    border: 1px solid rgba(229, 49, 34, 0.2);
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease;
}
.telegramChinaList:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 100%;
    margin-left: -9px;
    border-style: solid;
    border-top-width: 5px;
    border-left-width: 9.5px;
    border-right-width: 9.5px;
    border-color: transparent;
    border-top-color: #FFFFFF;
}
.footerBar_telegramIcon:hover .telegramChinaList {
    opacity: 1;
    z-index: 100;
    visibility: visible;
}

.telegramChinaList_item {
    display: block;
    padding: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    color: rgba(16, 16, 16, 0.9);
    text-decoration: none;
    border-bottom: 1px solid rgba(16, 16, 16, 0.06);
}
.telegramChinaList_item:last-of-type {
    border-bottom: 0;
}
.telegramChinaList_item:hover {
    color: #E53122;
}

@media screen and (max-width: 800px) {

    .footerBar_container {
        width: auto;
    }

    .footerBar_logo {
        width: 94px;
        height: 24px;
        margin: 20px;
    }

    .footerBar_devDoc {
        display: none;
    }

    .footerBar_right {
        margin-right: 25px;
    }
}

@media screen and (min-width: 1440px) {
    .footerBar_container {
        width: 1200px;
    }

    .footerBar_logo {
        width: 136px;
        height: 35px;
        margin: 44px 80px 44px 0;
    }

    .footerBar_devDoc {
        font-size: 16px;
        line-height: 21px;
    }
}


