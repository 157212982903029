
.community_section_2 {
    background-color: #FFFFFF;
    padding: 9.72vw 0 8.33vw;
}

.community_section_2_wrapper {
    width: 83.3vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.community_section_2_image {
    width: 38vw;
    height: 25.35vw;
    margin-left: 1.04vw;
}

.community_section_2_right {
    flex: 1;
    margin-left: 11.11vw;
    transition: all .5s ease;
    transform: translateY(50px);
    opacity: 0;
}
.community_section_2.show .community_section_2_right {
    transform: translateY(0);
    opacity: 1;
}

.community_section_2_title {
    font-weight: 600;
    font-size: 2.08vw;
    line-height: 2.92vw;
    color: #101010;
}

.community_section_2_desc {
    margin-top: 2.08vw;
    font-weight: 400;
    font-size: 1.11vw;
    line-height: 2.08vw;
    color: rgba(16, 16, 16, 0.9);
}

.community_section_2_button {
    display: block;
    margin-top: 3.47vw;
    width: 12.15vw;
    height: 2.78vw;
    box-sizing: border-box;
    padding: 0.88vw 0;
    background: #E53122;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1vw;
    line-height: 100%;
    color: #FFFFFF;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
}
.community_section_2_button:hover {
    background-color: #C1281B;
}


.community_section_3 {
    background-color: #FAFAFC;
}

.community_section_3_wrapper {
    width: 83.3vw;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.community_section_3_image {
    width: 61.389vw;
    height: 42.29vw;
}

.community_section_3_button {
    border: 1px solid #101010;
    backdrop-filter: blur(10px);
    border-radius: 4px;
    padding: 1.389vw 1.67vw;
    margin-top: 3.125vw;
    width: 25.7vw;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 1.11vw;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
.community_section_3_button:hover {
    color: #FFFFFF;
    background-color: #E53122;
    border-color: #E53122;
}

.community_section_3_telegram {
    position: relative;
    z-index: 1;
    margin-top: 3.125vw;
}

.community_section_3_telegramList {
    box-sizing: border-box;
    position: absolute;
    left: -1px;
    right: -1px;
    top: 100%;
    z-index: 1;
    background: #FAFAFC;
    border: 1px solid rgba(227, 49, 48, 0.2);
    border-radius: 4px;
    transition: all .3s ease;
    visibility: hidden;
    height: 0;
    overflow: hidden;
}
.enter .community_section_3_telegramList {
    visibility: visible;
    height: 7.5vw;
}

.community_section_3_telegram_arrow{
    transition: transform .5s ease;
}
.enter .community_section_3_telegram_arrow {
    transform: rotateZ(90deg);
}

.community_section_3_telegramList_item {
    display: block;
    padding: 1vw 0;
    border-bottom: 1px solid rgba(16, 16, 16, .06);
    text-align: center;
    color: rgba(16, 16, 16, 0.9);
    text-decoration: none;
}
.community_section_3_telegramList_item:last-of-type {
    border-bottom: 0;
}
.community_section_3_telegramList_item:hover {
    color:  #E53122;
}

.community_section_3_button span {
    flex: 1;
    margin-left: 1.389vw;
}

.community_section_3_left {
    margin-top: 8.433vw;
    transition: all .5s ease;
    transform: translateY(50px);
    opacity: 0;
    overflow: visible;
}
.community_section_3.show .community_section_3_left {
    transform: translateY(0);
    opacity: 1;
}

.community_section_3_desc {
    width: 110%;
}

.community_section_3_telegram_arrow_mobile {
    display: none;
}

@media screen and (max-width: 800px) {
    .community_section_2 {
        padding-top: 50px;
        padding-bottom: 80px;
    }

    .community_section_2_wrapper {
        width: auto;
        flex-direction: column-reverse;
    }

    .community_section_2_image {
        width: 319px;
        height: 203px;
        display: block;
        margin: 40px auto 0;
    }

    .community_section_2_right {
        margin-left: 0;
    }

    .community_section_2_title {
        font-size: 18px;
        line-height: 25px;
        text-align: center;
    }

    .community_section_2_desc {
        margin: 20px 20px 0;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
    }

    .community_section_2_button {
        width: 100px;
        height: 30px;
        font-size: 12px;
        line-height: 100%;
        padding: 9px 0;
        margin: 30px auto 0;
    }

    .community_section_3 {
        padding-top: 50px;
        padding-bottom: 266px;
        background: #FAFAFC url(../../images/communitySection3BgMobile.png) no-repeat center bottom / 100% auto;
    }

    .community_section_3_wrapper {
        width: auto;
        display: block;
    }

    .community_section_3_left {
        margin-top: 0;
    }

    .community_section_3_desc {
        width: unset;
    }

    .community_section_3_button {
        margin: 0 auto;
        padding: 15px 30px;
        width: 315px;
        font-size: 14px;
    }

    .community_section_3_telegram_arrow_mobile {
        display: block;
    }
    .enter .community_section_3_telegram_arrow_mobile {
        transform: rotateZ(90deg);
    }

    .community_section_3_telegram_arrow {
        display: none;
    }

    .community_section_3_telegram {
        margin: 30px auto 20px;
    }

    .enter .community_section_3_telegramList {
        height: 102px;
    }

    .community_section_3_telegramList_item {
        padding: 15px 0;
    }

    .community_section_3_image {
        display: none;
    }
}

@media screen and (min-width: 1440px) {
    .community_section_2 {
        padding: 140px 0 120px;
    }
    
    .community_section_2_wrapper {
        width: 1200px;
    }
    
    .community_section_2_image {
        width: 547px;
        height: 365px;
        margin-left: 15px;
    }
    
    .community_section_2_right {
        margin-left: 160px;
    }
    
    .community_section_2_title {
        font-size: 30px;
        line-height: 42px;
    }
    
    .community_section_2_desc {
        margin-top: 30px;
        font-size: 16px;
        line-height: 30px;
    }
    
    .community_section_2_button {
        margin-top: 50px;
        width: 175px;
        height: 40px;
        padding: 12px 0;
        font-size: 14px;
    }
    
    .community_section_3_wrapper {
        width: 1200px;
    }

    .community_section_3_left {
        margin-top: 120px;
    }

    .community_section_3_telegram {
        margin-top: 45px;
    }
    
    .community_section_3_image {
        width: 884px;
        height: 609px;
        margin-right: -120px;
    }
    
    .community_section_3_button {
        padding: 20px 24px;
        margin-top: 45px;
        width: 370px;
        font-size: 16px;
    }
    .community_section_3_button:last-child {
        margin-top: 20px;
    }
    
    .enter .community_section_3_telegramList {
        height: 108px;
    }
    
    .community_section_3_telegramList_item {
        padding: 14px 0;
    }
    
    .community_section_3_button span {
        margin-left: 20px;
    }
}