.headerBar {
  position: fixed;
  z-index: 100;
  width: 100%;
}

.headerBar.scrolling {
  background-color: rgba(12, 11, 14, 0.8);
}
.headerBar.scrolling .headerBar_container {
  backdrop-filter: blur(13px);
}
.headerBar_container {
  width: 81.94vw;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.logo {
  width: 9.44vw;
  height: 2.43vw;
  margin: 1.04vw 5.55vw 1.04vw 0;
}

.nav {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav_item {
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.53vw;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
  margin-right: 2.78vw;
  cursor: pointer;
}
.nav_item.current {
  border-bottom-color: #ffffff;
  color: #ffffff;
}
.nav_item:hover {
  color: #ffffff;
}

.langSelector {
  cursor: pointer;
  position: relative;
  width: 9.03vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedLang {
  font-weight: 500;
  font-size: 1vw;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
}
.langSelector:hover .selectedLang {
  color: #ffffff;
}

.selectedLang .selectedLang_arrow {
  opacity: 0.4;
  margin-left: 6px;
}
.langSelector:hover .selectedLang_arrow {
  opacity: 1;
}

.langList {
  position: absolute;
  left: 0;
  top: 51px;
  width: 130px;
  background: #fafafc;
  border: 1px solid rgba(229, 49, 34, 0.2);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  transition: all 0.5s ease;
  margin-top: 10px;
  opacity: 0;
  visibility: hidden;
}
.langSelector:hover .langList {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.lang {
  display: block;
  padding: 15px 0;
  color: rgba(16, 16, 16, 0.9);
  border-bottom: 1px solid rgba(16, 16, 16, 0.06);
  text-decoration: none;
}
.lang:last-child {
  border-bottom: none;
}
.lang:hover {
  color: #e53122;
}

.headerBar_mobile_menu,
.headerBar_mobile_close {
  display: none;
}

@media screen and (max-width: 800px) {
  .headerBar.mobileShow {
    background-color: #010101;
  }

  .headerBar_container {
    display: block;
    margin-left: 20px;
    width: auto;
  }

  .logo {
    margin: 12px 0;
    width: 94px;
    height: 24px;
  }

  .nav {
    display: none;
  }
  .mobileShow .nav {
    display: block;
  }

  .nav_item {
    display: block;
    border-bottom: 0;
    text-align: center;
    font-size: 16px;
    margin-top: 30px;
  }

  .langSelector {
    display: none;
    width: auto;
    text-align: center;
    margin-top: 35px;
  }
  .mobileShow .langSelector {
    display: flex;
    margin-bottom: 40px;
  }

  .selectedLang {
    font-size: 16px;
  }

  .langList {
    left: 50%;
    transform: translateX(-50%) translateY(-20px);
  }

  .headerBar_mobile_menu {
    position: absolute;
    right: 20px;
    top: 17px;
    width: 20px;
    height: 14px;
    display: block;
  }

  .headerBar_mobile_close {
    position: absolute;
    right: 20px;
    top: 17px;
    width: 12px;
    height: 12px;
    display: none;
  }

  .mobileShow .headerBar_mobile_menu {
    display: none;
  }
  .mobileShow .headerBar_mobile_close {
    display: block;
  }
}

@media screen and (min-width: 1440px) {
  .headerBar_container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
  }

  .logo {
    width: 136px;
    height: 35px;
    margin: 15px 80px 15px 0;
  }

  .nav_item {
    font-size: 16px;
    line-height: 22px;
    margin-right: 40px;
  }

  .langSelector {
    width: 130px;
  }

  .selectedLang {
    font-size: 14px;
  }
}
