.header {
    background: #0C0B0E url(../../../images/homepageHeaderBg.png) 0 0 / 100% 100%;
}

.headerContent {
    position: relative;
    z-index: 1;
    padding-top: 13.4vw;
    width: 83.3vw;
    margin: 0 auto;
}

.title {
    margin: 0 0 1.39vw;
    font-family: 'Lexend' -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 600;
    font-size: 4.86vw;
    line-height: 6.11vw;
    color: #FFFFFF;
    transition: all .5s ease;
    transform: translateY(50px);
    opacity: 0;
}
.show .title {
    transform: translateY(0);
    opacity: 1;
}

.titleDesc {
    max-width: 30vw;
    font-weight: 400;
    font-size: 1.39vw;
    line-height: 1.67vw;
    color: rgba(255, 255, 255, 0.8);
    transition: all .5s ease .3s;
    transform: translateY(50px);
    opacity: 0;
}
.show .titleDesc {
    transform: translateY(0);
    opacity: 1;
}

.mainButtons {
    margin: 3.19vw 0 6.6vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 1vw;
    transition: all .5s ease .6s;
    transform: translateY(50px);
    opacity: 0;
}
.show .mainButtons {
    transform: translateY(0);
    opacity: 1;
}

.mainButtons a {
    color: #FFFFFF;
    text-decoration: none;
}

.mainButton_start {
    width: 10.4167vw;
    height: 2.78vw;
    background: #E53122;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.39vw;
    cursor: pointer;
}
.mainButton_start:hover {
    background: linear-gradient(0deg, #C1281B, #C1281B);
}

.mainButton_toGithub {
    width: 10.4167vw;
    height: 2.78vw;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.mainButton_toGithub:hover {
    background: rgba(255, 255, 255, 0.2);
}

.usage {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10.69vw;
    transition: all .5s ease .9s;
    transform: translateY(50px);
    opacity: 0;
}
.show .usage {
    transform: translateY(0);
    opacity: 1;
}

.usage_num {
    font-weight: 700;
    font-size: 2.08vw;
    line-height: 1.67vw;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 0.83vw;
}

.usage_desc {
    font-weight: 400;
    font-size: 1.11vw;
    line-height: 1.67vw;
    text-align: left;
    color: rgba(255, 255, 255, 0.4);
}

.downloads {
    margin-right: 4.03vw;
}

.homepage_bannerBg {
    position: absolute;
    right: 0;
    top: 0;
    width: 51.875vw;
    height: 49.93vw;
}

.homepage_bannerBg_mobile {
    display: none;
}

@media screen and (min-width: 3840px) {
    .header {
        background-image: url(../../../images/homepageHeaderBgBig.png);
    }
}

@media screen and (max-width: 800px) {
    .header {
        background-image: url(../../../images/homepageHeaderBgMobile.png);
    }
    .headerContent {
        width: auto;
        margin: 0 auto;
        text-align: center;
        padding-top: 72px;
    }

    .title {
        font-weight: 600;
        font-size: 40px;
        line-height: 50px;
        color: #FFFFFF;
    }

    .titleDesc {
        max-width: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.8);
    }

    .mainButtons {
        justify-content: center;
        margin: 20px 0 30px;
    }

    .mainButton_start, .mainButton_toGithub {
        width: 100px;
        height: 30px;
        font-size: 12px;
    }

    .mainButton_start {
        margin-right: 20px;
    }

    .usage {
        justify-content: center;
        padding-bottom: 30px;
    }

    .downloads {
        margin-right: 40px;
    }

    .usage_num {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: rgba(255, 255, 255, 0.9);
        margin-right: 4px;
        margin-bottom: 0;
    }

    .usage_desc {
        margin-top: 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: rgba(255, 255, 255, 0.4);
    }

    .homepage_bannerBg_mobile {
        display: block;
        position: static;
        width: 100%;
        height: auto;
    }

    .homepage_bannerBg {
        display: none;
    }
}

@media screen and (max-width: 420px) {
    .headerContent {
        width: auto;
        padding-left: 20px;
        padding-top: 72px;
        text-align: left;
    }

    .mainButtons {
        margin: 20px 0 30px;
        justify-content: flex-start;
    }

    .mainButton_start, .mainButton_toGithub {
        font-size: 12px;
    }

    .usage {
        padding-bottom: 30px;
        justify-content: flex-start;
    }
}

@media screen and (min-width: 1440px) {
    .headerContent {
        width: 1200px;
        padding-top: 193px;
    }

    .title {
        margin: 0 0 20px;
        font-size: 70px;
        line-height: 88px;
    }

    .titleDesc {
        font-size: 20px;
        line-height: 24px;
    }

    .mainButtons {
        margin: 46px 0 95px;
        font-size: 14px;
    }

    .mainButton_start {
        width: 150px;
        height: 40px;
        margin-right: 20px;
    }
    
    .mainButton_toGithub {
        width: 150px;
        height: 40px;
    }

    .usage {
        padding-bottom: 154px;
    }

    .usage_num {
        font-size: 30px;
        line-height: 24px;
        margin-bottom: 12px;
    }
    
    .usage_desc {
        font-size: 16px;
        line-height: 24px;
    }
    
    .downloads {
        margin-right: 58px;
    }
    
    .homepage_bannerBg {
        width: 747px;
        height: 719px;
        margin-right: -120px;
    }
}