.content {
    padding-top: 8.33vw;
    background: linear-gradient(180deg, #FAFAFC 0%, #FAFAFC 67.71%, rgba(250, 250, 252, 0) 100%);;
}

.card1List {
    width: 83.3vw;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    transition: all .5s ease;
    transform: translateY(50px);
    opacity: 0;
}
.show.card1List {
    transform: translateY(0);
    opacity: 1;
}

.functions {
    width: 83.3vw;
    margin: 10.4167vw auto 0;
    padding-bottom: 5.694vw;
}

.function_title {
    font-weight: 600;
    font-size: 2.78vw;
    line-height: 3.89vw;
    color: #101010;
    margin-bottom: 4.167vw;
    transition: all .5s ease;
    opacity: 0;
    transform: translateY(50px);
}
.functions.show .function_title {
    opacity: 1;
    transform: translateY(0);
}

.functions_content {
    display: flex;
    align-items: flex-start;
}

.functions_left {
    flex: 1;
}

.functions_image {
    width: 27.78vw;
    height: 27.08vw;
    margin-right: -4.167vw;
    margin-top: -5.55vw;
}

.function_line {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.function {
    width: 27.5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .8s ease;
    opacity: 0;
    transform: scale(0);
    transform-origin: left center;
    margin-top: 0.56vw;
}
.functions.show .function {
    transform: scale(1);
    opacity: 1;
}
.function_line:nth-child(1) .function {
    transition-delay: .5s;
}
.function_line:nth-child(2) .function {
    transition-delay: 1s;
}
.function_line:nth-child(3) .function {
    transition-delay: 1.5s;
}

.function > img {
    width: 1.11vw;
    height: 1.11vw;
    margin-right: 10px;
}

.function > span {
    flex: 1;
    font-weight: 500;
    font-size: 1.11vw;
    line-height: 1.389vw;
    color: #101010;
    border-bottom: 0.5px solid rgba(16, 16, 16, .2);
    padding: 0.76vw 0;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.checkout_devdoc {
    width: 10.42vw;
    height: 2.78vw;
    background: #E53122;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3.4vw;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 1vw;
    font-weight: 600;
    text-decoration: none;
    transition: all .5s ease 2.3s;
    opacity: 0;
    transform: translateY(20px);
}
.checkout_devdoc:hover {
    background: linear-gradient(0deg, #C1281B, #C1281B);
}
.functions.show .checkout_devdoc {
    opacity: 1;
    transform: translateY(0);
}


.apps {
    padding: 10vw 0 7.15vw;
    background-color: #FFFFFF;
}

.apps_container {
    width: 83.3vw;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
}

.apps_left {
    flex: 1;
}

.apps_title {
    font-weight: 600;
    font-size: 2.78vw;
    line-height: 4.167vw;
    color: #101010;
}

.apps_title_num {
    color: #E53122;
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 800;
    font-size: 3.472vw;
}

.apps_bg {
    width: 50.625vw;
    height: 27.22vw;
    margin-left: -8.33vw;
    margin-top: 1.389vw;
}

.apps_rightSlider {
    width: 42.78vw;
    position: relative;
    overflow: hidden;
}
.apps_rightSlider:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 6.94vw;
    height: 100%;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}
.apps_rightSlider:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 6.94vw;
    height: 100%;
    background: linear-gradient(-90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

@keyframes appsSlider {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-78.125vw);
    }
}
.apps_rightSlider_imgContainer {
    width: 81.0417vw;
    height: 25.625vw;
    animation: appsSlider 25s linear infinite;
    overflow: visible;
    position: relative;
}
.apps_rightSlider_imgContainer_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}
.apps_rightSlider_imgContainer_img:last-of-type {
    left: 100%;
    margin-left: -2.9167vw;
}

.devCommunity {
    position: relative;
    padding: 3.472vw 0;
}

.devCommunity_bg {
    position: absolute;
    right: 0;
    top: 0;
    width: 39.236vw;
    height: 55.903vw;
}

.devCommunity_container {
    width: 83.3vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.devCommunity_leftBg {
    position: absolute;
    left: -18.4vw;
    top: 0;
    width: 50.139vw;
    height: 55.4167vw;
}

.devCommunity_left {
    transition: all .5s ease;
    transform: translateY(50px);
    opacity: 0;
}
.devCommunity_left.show {
    opacity: 1;
    transform: translateY(0);
}

.devCommunity_title {
    margin-top: 9.0278vw;
    font-weight: 600;
    font-size: 2.78vw;
    line-height: 3.89vw;
    color: #101010;
}

.devCommunity_desc {
    margin-top: 2.083vw;
    font-weight: 400;
    font-size: 1.11vw;
    line-height: 2.083vw;
    color: #101010;
}

.join_community {
    width: 10.4167vw;
    height: 2.78vw;
    background: #E53122;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    cursor: pointer;
    color: #FFFFFF;
    text-decoration: none;
}
.join_community:hover {
    background: linear-gradient(0deg, #C1281B, #C1281B);
}

.devCommunity_rightImage {
    width: 44.0278vw;
    height: 35.76vw;
}

.moreTools {
    margin-top: 2.78vw;
}

.moreTools_title {
    font-weight: 600;
    font-size: 2.78vw;
    line-height: 3.89vw;
    text-align: center;
    color: #101010;
    transition: all .5s ease;
    transform: translateY(50px);
    opacity: 0;
}
.moreTools.show .moreTools_title {
    transform: translateY(0);
    opacity: 1;
}

.moreTools_cardList {
    width: 83.3vw;
    margin: 4.167vw auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.moreTools .card2 {
    transition: transform .5s ease, opacity .5s ease;
    transform: translateY(50px);
    opacity: 0;
}
.moreTools.show .card2 {
    transform: translateY(0);
    opacity: 1;
}
.moreTools_cardList .card2:nth-child(1) {
    transition-delay: .2s;
}
.moreTools_cardList .card2:nth-child(2) {
    transition-delay: .3s;
}
.moreTools_cardList .card2:nth-child(3) {
    transition-delay: .4s;
}

.forward {
    margin: 0 auto;
    padding-bottom: 11.11vw;
    padding-top: 10.417vw;
    background: url(../../../images/forwardBg.png) 100% 100% / 100% 100%;
}

.forward_title {
    font-weight: 600;
    font-size: 2.78vw;
    line-height: 3.89vw;
    text-align: center;
    color: #101010;
    transition: all .5s ease;
    transform: translateY(50px);
    opacity: 0;
}
.forward.show .forward_title {
    transform: translateY(0);
    opacity: 1;
}

.forward_desc {
    margin: 2.78vw auto 0;
    width: 70.69vw;
    font-weight: 400;
    font-size: 1.11vw;
    line-height: 2.08vw;
    text-align: center;
    color: rgba(16, 16, 16, 0.9);
    transition: all .5s ease .3s;
    transform: translateY(50px);
    opacity: 0;
}
.forward.show .forward_desc {
    transform: translateY(0);
    opacity: 1;
}

.apps_bg_mobile {
    display: none;
}

.apps_rightSlider_imgContainer_mobile {
    display: none;
}

@media screen and (max-width: 800px) {
    .content {
        padding-top: 50px;
    }

    .card1List {
        display: block;
        width: auto;
    }

    .card1 {
        margin: 15px;
        width: auto;
    }

    .card1_icon img {
        width: 48px;
        height: 48px;
    }

    .card1_title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 8px;
    }

    .card1_desc {
        font-size: 12px;
        line-height: 20px;
    }

    .functions {
        width: auto;
        margin: 80px 0 0;
        padding-bottom: 0;
    }

    .function_title {
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
    }

    .functions_content {
        display: block;
    }

    .function_line {
        display: block;
        margin: 0 15px;
    }

    .function {
        width: auto;
        margin-top: 4px;
    }
    .function_line:nth-child(1) .function:first-child {
        transition-delay: .5s;
    }
    .function_line:nth-child(1) .function:nth-child(2) {
        transition-delay: 1s;
    }
    .function_line:nth-child(2) .function:nth-child(1) {
        transition-delay: 1.5s;
    }
    .function_line:nth-child(2) .function:nth-child(2) {
        transition-delay: 2s;
    }
    .function_line:nth-child(3) .function:nth-child(1) {
        transition-delay: 2.5s;
    }
    .function_line:nth-child(3) .function:nth-child(2) {
        transition-delay: 3s;
    }

    .function img {
        width: 14px;
        height: 14px;
        margin-top: -5px;
    }

    .function span {
        font-size: 14px;
        line-height: 20px;
        margin-left: -24px;
        padding: 16px 0 16px 24px;
    }
    
    .checkout_devdoc {
        display: none;
    }

    .functions_image {
        margin-top: 10px;
        width: 100%;
        height: auto;
        margin-right: 0;
    }

    .apps {
        padding-top: 80px;
        padding-bottom: 65px;
    }

    .apps_container {
        width: 100%;
        display: block;
    }

    .apps_left {
        margin-right: 0;
    }

    .apps_title {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
    }

    .apps_title_num {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 30px;
    }

    .apps_bg {
        display: none;
    }

    .apps_bg_mobile {
        display: block;
        width: 100%;
        height: auto;
        margin-top: 20px;
    }

    .apps_rightSlider {
        margin: 0 auto;
        width: auto;
        max-width: 700px;
    }

    .apps_rightSlider_imgContainer {
        display: none;
    }

    @keyframes appsSliderMobile {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-700px);
        }
    }
    .apps_rightSlider_imgContainer_mobile {
        display: block;
        width: 717px;
        height: 224px;
        animation: appsSliderMobile 30s linear infinite;
        overflow: visible;
        position: relative;
    }
    .apps_rightSlider_imgContainer_img_mobile {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
    .apps_rightSlider_imgContainer_img_mobile:last-of-type {
        left: 100%;
        margin-left: -17px;
    }

    .devCommunity_container {
        width: auto;
        display: block;
    }

    .devCommunity {
        padding-top: 40px;
        background: url(../../../images/devCommunityBgMobile.png) no-repeat 0 0 / 100% 100%;
    }

    .devCommunity_title {
        margin-top: 40px;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
    }

    .devCommunity_desc {
        margin: 10px 20px 0;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
    }

    .devCommunity_leftBg {
        display: none;
    }

    .join_community {
        width: 100px;
        height: 30px;
        margin: 20px auto 0;
        font-size: 12px;
        line-height: 100%;
    }

    .devCommunity_right {
        padding: 30px;
    }

    .devCommunity_rightImage {
        width: 100%;
        height: auto;
    }

    .devCommunity_bg {
        display: none;
    }

    .moreTools_title {
        margin-top: 30px;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
    }

    .moreTools_cardList {
        display: block;
        width: auto;
        margin-top: 30px;
    }

    .card2 {
        width: auto;
        margin: 15px;
        padding: 30px 20px;
        height: auto;
    }

    .forward {
        margin-top: 80px;
        padding-top: 0;
        background-image: url(../../../images/forwardBgMobile.png);
    }

    .forward_title {
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
    }

    .forward_desc {
        width: auto;
        margin: 30px 33px 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 30px;
        text-align: center;
    }
}

@media screen and (min-width: 1440px) {
    .content {
        padding-top: 120px;
    }

    .card1List {
        width: 1200px;
    }

    .functions {
        width: 1200px;
        margin: 150px auto 0;
        padding-bottom: 82px;
    }

    .function_title {
        font-size: 40px;
        line-height: 56px;
        margin-bottom: 40px;
    }

    .functions_image {
        width: 400px;
        height: 390px;
        margin-right: -60px;
        margin-top: -80px;
    }

    .function {
        width: 396px;
        margin-top: 8px;
    }

    .function > img {
        width: 16px;
        height: 16px;
    }

    .function > span {
        font-size: 16px;
        line-height: 20px;
        padding: 11px 0;
    }

    .checkout_devdoc {
        width: 175px;
        height: 40px;
        margin-top: 49px;
        font-size: 14px;
    }

    .apps {
        padding: 144px 0 60px;
    }

    .apps_container {
        width: 1200px;
    }

    .apps_title {
        font-size: 40px;
        line-height: 60px;
    }

    .apps_bg {
        width: 729px;
        height: 382px;
        margin-left: -120px;
    }

    .apps_rightSlider {
        width: 616px;
    }
    .apps_rightSlider:before {
        width: 100px;
    }
    .apps_rightSlider:after {
        width: 100px;
    }

    @keyframes appsSliderBig {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-1125px);
        }
    }

    .apps_rightSlider_imgContainer {
        animation-name: appsSliderBig;
    }

    .apps_rightSlider_imgContainer {
        width: 1167px;
        height: 369px;
    }
    .apps_rightSlider_imgContainer_img:last-of-type {
        margin-left: -42px;
    }

    .devCommunity {
        padding: 50px 0;
    }
    
    .devCommunity_container {
        width: 1200px;
    }

    .devCommunity_leftBg {
        left: -265px;
        top: 0;
        width: 722px;
        height: 798px;
    }

    .devCommunity_title {
        margin-top: 130px;
        font-size: 40px;
        line-height: 56px;
    }
    
    .devCommunity_desc {
        margin-top: 30px;
        font-size: 16px;
        line-height: 30px;
    }

    .join_community {
        width: 175px;
        height: 40px;
        font-weight: 600;
    }
    
    .devCommunity_rightImage {
        width: 634px;
        height: 515px;
    }

    .moreTools_title {
        font-size: 40px;
        line-height: 56px;
    }

    .moreTools_cardList {
        width: 1200px;
        margin: 60px auto 0;
    }

    .forward {
        padding-bottom: 160px;
        padding-top: 150px;
    }
    
    .forward_title {
        font-size: 40px;
        line-height: 56px;
    }
    
    .forward_desc {
        margin: 40px auto 0;
        width: 1018px;
        font-size: 16px;
        line-height: 30px;
    }
}