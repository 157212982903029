.card1 {
    width: 26.67vw;
    padding: 2.08vw 2.08vw 2.43vw;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(211, 211, 211, 0.1);
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
}

.card1_icon img {
    width: 3.89vw;
    height: 3.89vw;
}
.card1_icon {
    margin-right: 1.94vw;
}

.card1_content {
    flex: 1;
}

.card1_title {
    font-weight: 600;
    font-size: 1.67vw;
    line-height: 2.36vw;
    color: #101010;
    margin-bottom: 1vw;
    margin-top: 5px;
}

.card1_desc {
    font-weight: 400;
    font-size: 1.11vw;
    line-height: 1.8vw;
    color: #101010;
}

@media screen and (max-width: 800px) {
    .card1 {
        padding: 30px 20px;
    }

    .card1_icon {
        margin-right: 20px;
    }
}

@media screen and (min-width: 1440px) {
    .card1 {
        width: 384px;
        padding: 30px;
    }
    .card1:first-child {
        padding-right: 24px;
    }

    .card1_icon img {
        width: 56px;
        height: 56px;
    }
    .card1_icon {
        margin-right: 28px;
    }

    .card1_title {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 14px;
    }
    
    .card1_desc {
        font-size: 16px;
        line-height: 26px;
    }
}