.serviceAlert {
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: #161616;
  justify-content: center;
}

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../../images/alert.png) no-repeat center;
}
.p {
  color: #ffe1b5;
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  margin: 0;
}
p .text {
  margin-left: 5px;
}
.button {
  background: #3d424a;
  color: #fff;
  padding: 0 30px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  cursor: pointer;
  margin-left: 10px;
  flex: 0 0 auto;
}

.button:hover {
  background-color: #da3b2f;
}

@media screen and (max-width: 750px) {
  .serviceAlert {
    position: fixed;
    bottom: 0;
    flex-wrap: wrap;
    padding: 9px 35px;
    padding-left: 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .p {
    display: flex;
    padding-left: 10px;
    align-items: flex-start;
  }
  .icon {
    display: block;
    flex: 1 0 auto;
    margin-top: 5px;
  }
  .text {
    font-size: 11px;
    line-height: 20px;
    margin-left: 5px;
  }
  .button {
    line-height: 20px;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 10px;
    font-weight: 700;
    text-transform: capitalize;
  }
}
