.card2 {
    position: relative;
    width: 26.39vw;
    height: 16.11vw;
    box-sizing: border-box;
    padding: 2.78vw 2.08vw;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 10px;
    overflow: hidden;
    transition: all .5s ease;
}
.card2:hover {
    background-color: #1C1A1D;
}

.card2_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.card2_icon {
    width: 2.5vw;
    height: 2.5vw;
}

.card2_icon_black {
    display: none;
    width: 2.5vw;
    height: 2.5vw;
}

.card2:hover .card2_icon {
    display: none;
}

.card2:hover .card2_icon_black {
    display: block;
}

.card2_title {
    margin-left: 1vw;
    font-weight: 900;
    font-size: 1.67vw;
    line-height: 2vw;
    color: #101010;
}
.card2:hover .card2_title {
    color: #FFFFFF;
}

.card2_desc {
    height: 4.17vw;
    margin-top: 1.39vw;
    font-weight: 400;
    font-size: 1.11vw;
    line-height: 2.08vw;
    color: rgba(16, 16, 16, 0.9);
}
.card2:hover .card2_desc {
    color: rgba(255, 255, 255, .9);
}

.card2_goDetail {
    margin-top: 2.08vw;
    font-weight: 500;
    font-size: 1vw;
    line-height: 100%;
    color: #E53122;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.card2_goDetail_rightArrow {
    margin-left: 10px;
    width: 1.11vw;
    height: 1.11vw;
    transition: all .3s ease;
}

.card2_goDetail:hover .card2_goDetail_rightArrow {
    transform: translateX(5px);
}

.card2_bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 4.167vw;
    height: 4.167vw;
}

@media screen and (max-width: 800px) {
    .card2_icon, .card2_icon_black {
        width: 36px;
        height: 36px;
    }

    .card2_title {
        font-size: 16px;
        line-height: 19px;
        margin-left: 20px;
    }

    .card2_desc {
        height: 44px;
        margin-left: 50px;
        margin-top: 2px;
        font-size: 12px;
        line-height: 20px;
        margin-left: 56px;
    }

    .card2_goDetail {
        margin-left: 56px;
        margin-top: 20px;
        font-size: 12px;
        line-height: 17px;
    }

    .card2_goDetail_rightArrow {
        width: 16px;
        height: 16px;
    }
}

@media screen and (max-width: 800px) {
    .card2_bg {
        width: 60px;
        height: 60px;
    }
}

@media screen and (min-width: 1440px) {
    .card2 {
        width: 380px;
        height: 232px;
        padding: 40px 30px;
    }

    .card2_icon {
        width: 36px;
        height: 36px;
    }
    
    .card2_icon_black {
        width: 36px;
        height: 36px;
    }
    
    .card2_title {
        margin-left: 14px;
        font-size: 24px;
        line-height: 29px;
    }
    
    .card2_desc {
        height: 60px;
        margin-top: 20px;
        font-size: 16px;
        line-height: 30px;
    }
    
    .card2_goDetail {
        margin-top: 30px;
        font-size: 14px;
    }
    
    .card2_goDetail_rightArrow {
        width: 16px;
        height: 16px;
    }
    
    .card2_bg {
        width: 60px;
        height: 60px;
    }
}