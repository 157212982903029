
.community_header {
    background: #0C0B0E;
    position: relative;
}

.community_header_container {
    width: 83.3vw;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.community_banner {
    position: relative;
    z-index: 1;
    overflow: visible;
}

.community_title {
    width: 200%;
    margin: 12.29vw 0 1.389vw;
    font-weight: 600;
    font-size: 4.167vw;
    line-height: 5.83vw;
    color: #FFFFFF;
    transition: all .5s ease;
    transform: translateY(50px);
    opacity: 0;
}
.show .community_title {
    transform: translateY(0);
    opacity: 1;
}

.community_desc {
    width: 140%;
    margin-top: 2.57vw;
    font-weight: 400;
    font-size: 1.389vw;
    line-height: 1.67vw;
    color: rgba(255, 255, 255, 0.8);
    transition: all .5s ease .5s;
    transform: translateY(50px);
    opacity: 0;
}
.show .community_desc {
    transform: translateY(0);
    opacity: 1;
}

.communityLeftBg {
    position: absolute;
    left: 0;
    top: 0;
    width: 21.94vw;
    height: 100%;
}

.community_header_bg {
    width: 51.875vw;
    height: 34.722vw;
}


.community_header_bg_mobile {
    display: none;
}
@media screen and (max-width: 800px) {
    .community_header {
        background: #0C0B0E url(../../../images/communityLeftBgMobile.png) no-repeat left center / auto 100%;
    }

    .community_header_container {
        width: auto;
        display: block;
        padding: 50px 0;
    }

    .communityLeftBg {
        display: none;
    }

    .community_header_bg {
        display: none;
    }

    .community_header_bg_mobile {
        display: block;
        width: 323px;
        height: 174px;
        margin: 25px auto 0;
    }

    .community_title {
        width: unset;
        margin-top: 30px;
        font-size: 30px;
        line-height: 42px;
        text-align: center;
    }

    .community_desc {
        width: unset;
        margin-top: 10px;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
    }
}

@media screen and (min-width: 1440px) {
    .community_header_container {
        width: 1200px;
    }
    
    .community_title {
        margin: 177px 0 20px;
        font-size: 60px;
        line-height: 84px;
    }
    
    .community_desc {
        margin-top: 37px;
        font-size: 20px;
        line-height: 24px;
    }

    .communityLeftBg {
        width: 316px;
    }
    
    .community_header_bg {
        width: 747px;
        height: 500px;
    }
}